import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/buildings",
    name: "buildings",
    component: () => import("@/views/buildings/Buildings.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":slug",
        name: "building",
        component: () => import("@/views/buildings/Building.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "summary",
            name: "summary",
            component: () => import("@/views/buildings/Summary.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Building",
            },
            children: [
              {
                path: "building-profile",
                name: "building-profile",
                component: () =>
                  import("@/views/buildings/BuildingProfile.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Building Profile",
                },
              },
              //not sure exactly how I'll end up dealing with this route, but lets pop her in here for now
              {
                path: "equipment-list",
                name: "equipment-list",
                component: () => import("@/views/buildings/EquipmentList.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Equipment List",
                },
              },
              {
                path: "ai-status",
                name: "ai-status",
                component: () => import("@/views/buildings/AiStatus.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "AI Status",
                },
              },
              {
                path: "control-status",
                name: "control-status",
                component: () => import("@/views/buildings/ControlStatus.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Control Status",
                },
              },
              {
                path: "commands",
                name: "command_log",
                component: () => import("@/views/buildings/Commands.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Commands",
                },
              },
              {
                path: "release",
                name: "release",
                component: () => import("@/views/buildings/Release.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Release",
                },
              },
            ],
          },
          {
            path: "energy",
            name: "energy",
            component: () => import("@/views/buildings/Energy.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Energy",
            },
            children: [
              {
                path: "bill",
                name: "energy-bill",
                component: () => import("@/views/buildings/EnergyBill.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Bill",
                },
                children: [
                  {
                    path: "electricity",
                    name: "energy-electricity",
                    component: () =>
                      import("@/views/buildings/Electricity.vue"),
                    beforeEnter: authGuard,
                    meta: {
                      requiresAuth: true,
                      title: "Electricity",
                    },
                    children: [
                      {
                        path: "consumption",
                        name: "energy-consumption",
                        component: () =>
                          import("@/views/buildings/Consumption.vue"),
                        beforeEnter: authGuard,
                        meta: {
                          requiresAuth: true,
                          title: "Consumption",
                        },
                      },
                      {
                        path: "power",
                        name: "energy-power",
                        component: () => import("@/views/buildings/Power.vue"),
                        beforeEnter: authGuard,
                        meta: {
                          requiresAuth: true,
                          title: "Power",
                        },
                      },
                    ],
                  },
                  {
                    path: "gas",
                    name: "energy-gas",
                    component: () => import("@/views/buildings/Gas.vue"),
                    beforeEnter: authGuard,
                    meta: {
                      requiresAuth: true,
                      title: "Gas",
                    },
                  },
                ],
              },
              {
                path: "virtual-metering",
                name: "energy-virtual-metering",
                component: () =>
                  import(
                    "@/views/buildings/virtual_metering/EnergyVirtualMetering.vue"
                  ),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Virtual Metering",
                },
              },
            ],
          },
          {
            path: "monetary",
            name: "monetary",
            component: () => import("@/views/buildings/Monetary.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Monetary",
            },
            children: [
              {
                path: "electricity",
                name: "monetary-electricity",
                component: () =>
                  import("@/views/buildings/MonetaryElectricity.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Electricity",
                },
                children: [
                  {
                    path: "consumption",
                    name: "monetary-consumption",
                    component: () =>
                      import("@/views/buildings/MonetaryConsumption.vue"),
                    beforeEnter: authGuard,
                    meta: {
                      requiresAuth: true,
                      title: "Consumption",
                    },
                  },
                  {
                    path: "power",
                    name: "monetary-power",
                    component: () =>
                      import("@/views/buildings/MonetaryPower.vue"),
                    beforeEnter: authGuard,
                    meta: {
                      requiresAuth: true,
                      title: "Power",
                    },
                  },
                ],
              },
              {
                path: "gas",
                name: "monetary-gas",
                component: () => import("@/views/buildings/MonetaryGas.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Gas",
                },
              },
            ],
          },
          {
            path: "emissions",
            name: "emissions",
            component: () => import("@/views/buildings/Emissions.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Emissions",
            },
            children: [
              {
                path: "bill",
                name: "emissions-bill",
                component: () => import("@/views/buildings/EmissionsBill.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Bill",
                },
              },
              {
                path: "virtual-metering",
                name: "emissions-virtual-metering",
                component: () =>
                  import(
                    "@/views/buildings/virtual_metering/EmissionsVirtualMetering.vue"
                  ),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Emissions Reductions",
                },
              },
            ],
          },
          {
            path: "comfort",
            name: "comfort",
            component: () => import("@/views/buildings/Comfort.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Comfort",
            },
            children: [
              {
                path: "current",
                name: "comfort-current",
                component: () => import("@/views/buildings/ComfortCurrent.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Current",
                },
              },
              {
                path: "history",
                name: "comfort-history",
                component: () => import("@/views/buildings/ComfortHistory.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "History",
                },
              },
            ],
          },
          {
            path: "advanced-metrics",
            name: "advanced-metrics",
            component: () => import("@/views/buildings/AdvancedMetrics.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Advanced Metrics",
            },
            children: [
              {
                path: "energy-cost-index",
                name: "advanced-metrics-energy-cost-index",
                component: () =>
                  import("@/views/buildings/EnergyCostIndex.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Cost Index",
                },
              },
              {
                path: "energy-usage-intensity",
                name: "advanced-metrics-energy-usage-intensity",
                component: () =>
                  import("@/views/buildings/EnergyUsageIntensity.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Usage Intensity",
                },
              },
              {
                path: "load-factor",
                name: "advanced-metrics-load-factor",
                component: () => import("@/views/buildings/LoadFactor.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Load Factor",
                },
              },
            ],
          },
          {
            path: "scope-page",
            name: "scope-page",
            component: () => import("@/views/buildings/GHGEmissions.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "Scope Pages",
            },
            children: [
              {
                path: "scope-summary",
                name: "scope-summary",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Summary.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Summary",
                },
              },
              {
                path: "scope-scope1",
                name: "scope-scope1",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Scope1.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Scope 1",
                },
              },
              {
                path: "scope-scope2",
                name: "scope-scope2",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Scope2.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Scope 2",
                },
              },
              {
                path: "emissions-reporting",
                name: "emissions-reporting",
                component: () =>
                  import("@/views/buildings/GHGEmissions/ScopeReporting.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Emissions Reporting",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/decarb-buildings",
    name: "decarb-buildings",
    component: () => import("@/views/buildings/Buildings.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":slug",
        name: "decarb-building",
        component: () => import("@/views/buildings/Building.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "ghg-emissions",
            name: "ghg-emissions",
            component: () => import("@/views/buildings/GHGEmissions.vue"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
              title: "GHG Emissions",
            },
            children: [
              {
                path: "ghg-emissions-summary",
                name: "ghg-emissions-summary",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Summary.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "ghg-emissions-scope1",
                name: "ghg-emissions-scope1",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Scope1.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Scope 1",
                },
              },
              {
                path: "ghg-emissions-scope2",
                name: "ghg-emissions-scope2",
                component: () =>
                  import("@/views/buildings/GHGEmissions/Scope2.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Scope 2",
                },
              },
              {
                path: "ghg-emissions-reporting",
                name: "ghg-emissions-reporting",
                component: () =>
                  import("@/views/buildings/GHGEmissions/ScopeReporting.vue"),
                beforeEnter: authGuard,
                meta: {
                  requiresAuth: true,
                  title: "Emissions Reporting",
                },
              },
            ],
          },
          {
            path: "edit",
            name: "edit-decarb",
            component: () => import("@/views/buildings/EditDecarb"),
            beforeEnter: authGuard,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: () => import("@/views/integrations/Integrations.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Integrations",
    },
    children: [
      {
        path: "utility-account",
        name: "Utility Account",
        component: () => import("@/views/integrations/Urjanet.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "Utility Integration",
        },
      },
      {
        path: "csv-upload",
        name: "CSV Upload",
        component: () => import("@/views/integrations/CSVUpload.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "CSV Upload",
        },
      },
    ],
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import("@/views/portfolios/Portfolio.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Portfolio",
    },
  },
  // {
  //   path: '/new-portfolio',
  //   name: 'new-portfolio',
  //   component: () => import('@/views/portfolios/NewPortfolio.vue'),
  //   beforeEnter: authGuard,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'Portfolio',
  //   },
  // },
  {
    path: "/building-management",
    name: "building-management",
    component: () =>
      import("@/views/buildings/Management/BuildingManagement.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Building Management",
    },
  },
  {
    path: "/alarm-management",
    name: "alarm-management",
    component: () => import("@/views/alert/AlarmManagement.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Alarm Management",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profiles/Profile.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Profile",
    },
    children: [
      {
        path: "information",
        name: "information",
        component: () => import("@/views/profiles/Information.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "Information",
        },
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/profiles/Settings.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "Setting",
        },
      },
      {
        path: "provider",
        name: "provider",
        component: () => import("@/views/profiles/Provider.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "Provider",
        },
      },
      {
        path: "notification-center",
        name: "notification-center",
        component: () => import("@/views/profiles/NotificationCenter.vue"),
        beforeEnter: authGuard,
        meta: {
          requiresAuth: true,
          title: "Notification Center",
        },
      },
    ],
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/ContactUs.vue"),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true,
      title: "Contact Us",
    },
  },
  {
    path: "/term-of-use",
    name: "term-of-use",
    component: () => import("@/views/TermOfUse.vue"),
    meta: {
      requiresAuth: true,
      title: "Term of User",
    },
  },
  {
    path: "/:catchAll(.*)*",
    name: "error",
    component: () => import("@/views/Error404.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    return { x: 0, y: 0 };
  },
});

/* router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.configure({ parent: '#app' });
    NProgress.start();
    NProgress.configure({ minimum: 0.2 });
    NProgress.configure({ easing: 'ease', speed: 1000 });
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.configure({ minimum: 0.99 });;
}) */

export default router;
