import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { ApolloLink, from } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import store from "@/store";

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_APOLLO_CLIENT_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${store.getters.token}` || null,
      "brainbox-app": 0,
    },
  }));
  return forward(operation);
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
