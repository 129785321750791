<template>
  <div
    v-if="!this.$route.path.includes('reporting')"
    class="font-bold mb-2 pb-2 md:text-4xl px-0 md:px-0 leading-tighter relative overflow-hidden tracking-wide"
  >
    <span>
      <!-- {{ (this.$route.path.includes('portfolio') && !this.$route.path.includes('new-portfolio')) ? 'Buildings':routeName }} -->
      {{ routeName }}
    </span>
    <transition @enter="enterName" @leave="leaveName" :css="false">
      <div class="flex flex-col md:flex-row justify-between md:items-center">
        <span
        v-if="hasBuildingSlug && buildingName"
        class="block text-gray-700 text-2xl my-4 font-normal flex flex-row items-center"
        >
          <img src="@/assets/icons/arrow_right.svg" class="mr-2" alt="">
          {{ buildingName }}
        </span>
        <span
        v-else-if="hasDecarbBuildingSlug && decarbBuilding"
        class="block text-gray-700 text-2xl my-4 font-normal flex flex-row items-center"
        >
          <img src="@/assets/icons/arrow_right.svg" class="mr-2" alt="">
          {{ decarbBuilding }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap';
export default {
  name: 'PageTitle',
  props: [
    'hasBuildingSlug',
    'hasDecarbBuildingSlug',
    'decarbBuilding',
    'buildingName',
    'routeName',
  ],
  methods: {
    enterName(el, done) {
      gsap.from(el, {
        opacity: 0,
        duration: 1,
        onComplete: done,
      });
    },
    leaveName(el, done) {
      gsap.to(el, {
        opacity: 0,
        duration: 0.3,
        onComplete: done,
      });
    },
  },
};
</script>
