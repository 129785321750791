import { ref, computed } from "vue";

export const collapsed = screen.width <= 768 ? ref(true) : ref(false);
export const showStats = ref(true);
export const toggleSidebar = () => (collapsed.value = !collapsed.value);

export const SIDEBAR_WIDTH = 288;
export const SIDEBAR_WIDTH_COLLAPSED = 80;

export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`,
);

export const maxWindowsWidth = computed(
  () =>
    `${
      collapsed.value ? SIDEBAR_WIDTH_COLLAPSED + 1920 : SIDEBAR_WIDTH + 1920
    }px`,
);
