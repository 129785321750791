<template>
    <li class="flex flex-col text-base text-black cursor-pointer px-3 py-2">
        <div
            @click="toggleShow()"
            class="flex items-center justify-between py-2 cursor-pointer">
            <div class="flex flex-row">
                <img class="shrink-0 mr-2" :src="getImgUrl(icon)">
                <span>{{ $t(title) }}</span>
            </div>
            <img
                class="menu-icon"
                :src="getImgUrl('expand_more_black.svg')"
                :class="{'rotate-180': showMenu }">
        </div>
        <transition name="expand">
            <ul
             v-if="showMenu"
             class=" menu-items justify-between text-gray-500 flex flex-col"
             :class="this.class">
                <slot></slot>
            </ul>
        </transition>
    </li>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true},
        height: { type: String, required: true },
        icon: { type: String, required: true},
    },
    data() {
        return {
            showMenu: false
        };
    },
    methods: {
        toggleShow() {
            this.showMenu = !this.showMenu
        },
        getImgUrl: function (img) { 
            return require('@/assets/icons/' + img);
        },
    },
    computed: {
        class() {
            return this.showMenu ? this.height : "h-0";
        }
    },
}
</script>

<style scoped>
.menu-icon {
    transition: 0.2s linear;
}
.menu-items {
    transition: 0.2s ease;
}
.rotate-180 {
    transform: rotate(180deg);
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height .5s ease;
  max-height: 400px;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>