<template>
  <div id="app" class="w-screen font-atlasgrotesk">
    <Icons />
    <TopLine v-if="app_loaded" :style="{ maxWidth: maxWindowsWidth }" />
    <!-- <Level1 v-if="app_loaded" /> -->
    <div
      id="main-content"
      class="main-content flex flex-col"
      :style="{ marginLeft: sidebarWidth }">
      <TermOfUsePopup
        v-if="app_loaded"
        v-show="showPopup"
        @close="showPopup = false"
        class="z-[999]" />
      <SideBar v-if="app_loaded" class="font-atlasgrotesk" />
      <Header v-if="app_loaded" />
      <router-view v-if="app_loaded" />
      <!-- <Footer v-if="app_loaded" /> -->
    </div>
    <transition name="fade-loader" mode="out-in">
      <Loader v-if="!app_loaded" />
    </transition>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import Icons from '@/components/Icons';
import Header from '@/components/Header';
import TermOfUsePopup from './components/TermOfUsePopup';
import SideBar from './views/layouts/sidebar';
import { sidebarWidth, maxWindowsWidth } from './views/layouts/sidebar/state';
import TopLine from './components/topLine';
/* import Level1 from '@/components/menus/Level1'; */
/* import Footer from '@/components/Footer'; */
/* import Test from '@/components/Test'; */
export default {
  name: 'App',
  props: {},
  setup() {
    return { sidebarWidth, maxWindowsWidth };
  },
  data() {
    return {
      showPopup: this.getInitialValue(),
    };
  },
  methods: {
    getInitialValue() {
      if (localStorage.getItem('isShowPopup') === null) {
        return true;
      } else {
        return localStorage.getItem('isShowPopup') === 'true';
      }
    },
  },
  components: { SideBar, Header, Loader, Icons, TopLine, TermOfUsePopup },
  computed: {
    app_loaded() {
      return this.$store.getters['loaded'];
    },
  },
  async created() {
    this.$store.commit('SET_ABB_USER', process.env.VUE_APP_USER === 'abb');
    if (process.env.VUE_APP_USER === 'abb') {
      const favicon = document.getElementById('favicon');
      favicon.href = process.env.BASE_URL + 'abb_favicon.ico';
    }

    //dynamically set the title for Brainbox/ABB
    window.document.title =
      process.env.VUE_APP_USER === 'abb'
        ? 'Dashboard | ABB Inc'
        : 'Dashboard | BrainBox AI';

    //get user token
    await this.$store.dispatch('get_token', { auth0: this.$auth0 });
    // get user
    await this.$store.dispatch('get_user_data', { auth0: this.$auth0 });
    // get buildings
    await this.$store.dispatch('get_buildings');

    //get decarb buildings

    //await this.$store.dispatch('get_decarb_buildings');
    // get building types / progress
    await this.$store.dispatch('get_building_details');
    // get messages
    await this.$store.dispatch('get_messages');

    await this.$store.dispatch('get_notification_count');

    // set pages
    this.$store.dispatch('set_pages');

    this.$store.commit('SET_LOADED', true);
  },
};
</script>

<style lang="stylus">


#app
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.fade-loader-enter-active,
.fade-loader-leave-active
  transition opacity 1s .5s
.fade-loader-enter,
.fade-loader-leave-to
  opacity 0

.main-content
  transition 0.3s ease
</style>
