<template>
  <router-link
    :to="to"
    :class="{ active: isActive }"
    class="link flex items-center cursor-pointer relative font-normal text-base select-none p-1 h-12 hover:bg-black hover:text-white">
    <img class="icon shrink-0 mx-2" :src="getImgUrl(icon)" />
    <transition class="fade">
      <span
        v-if="
          !collapsed ||
          label == 'my-account' ||
          label == 'utility-provider-setup'
        "
        class="inline-flex">
        <span>{{ $t(label) }}</span>
        <span
          v-if="label == 'notification-center-release'"
          class="bg-bbred-500 text-white text-xs font-normal rounded-2xl py-1 px-2 border border-white ml-3"
          :class="[notificationsCount > 0 ? '' : 'hidden']">
          {{ notificationsCount }}
        </span>
      </span>
    </transition>
    <!-- <div v-if="collapsed" class="icon-label text-base items-center flex pl-4">{{label}}</div> -->
  </router-link>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useRoute } from 'vue-router';
import { collapsed } from './state';

export default {
  props: {
    to: { type: String, required: false },
    icon: { type: String, required: true },
    label: { type: String, required: true },
  },
  setup(props) {
    const route = useRoute();
    const isActive = computed(() => route.path.includes === props.to);
    return { isActive, collapsed };
  },

  computed: {
    notificationsCount() {
      return this.$store.state.notification_count;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave {
  opacity: 0;
}
.link {
  border-radius: 0.5rem;
  transition: ease-in-out all 0.3s;
}
.link:hover img {
  filter: invert();
}

.router-link-active {
  background-color: black;
  color: white;
}
.router-link-active img {
  filter: invert();
}
.icon-label {
  opacity: 0;
  float: left;
  left: 50px;
  width: 0px;
  height: 100%;
  position: absolute;
  transition: width 0.1s;
  background-color: black;
  border-radius: 0.5rem;
}

.link:hover .icon-label {
  width: 150px;
  opacity: 1;
}
</style>
