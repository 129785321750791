import { createStore } from "vuex";
import { apolloClient } from "@/graphql/client";
import {
  QUERY_USER_BY_EMAIL,
  QUERY_BUILDINGS_BY_ID,
  QUERY_DEV_ENUMERATIONS,
  QUERY_MESSAGES,
  QUERY_NOTIFICATION,
  QUERY_FEATURE_RELEASES,
  QUERY_DECARB_BUILDINGS,
} from "@/graphql";

const compile_permissions = (roles) => {
  let scopes = [];
  roles.map((role) => {
    role.permissions.map((permission) => scopes.push(permission.scope));
  });
  return [...new Set([...scopes])];
};

export default createStore({
  state: {
    selectedBuilding: null,
    token: null,
    user: null,
    buildings: [],
    decarb_buildings: [],
    building_types: [],
    building_progress: [],
    messages: [],
    loaded: false,
    isAbbUser: false,
    pages: [],
    notification_count: 0,
  },
  data() {
    return {
      read_notifications: [],
    };
  },
  mutations: {
    SET_SELECTED_BUILDING(state, building) {
      state.selectedBuilding = building;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      const { roles } = user;
      const role_slugs = roles.map((role) => role.slug);
      state.user = {
        ...user,
        roles: role_slugs,
        permissions: compile_permissions(roles),
      };
    },
    SET_ABB_USER(state, isAbbUser) {
      state.isAbbUser = isAbbUser;
    },
    SET_BUILDINGS(state, buildings) {
      state.buildings = buildings;
    },

    SET_DECARB_BUILDINGS(state, decarb_buildings) {
      state.decarb_buildings = decarb_buildings;
    },
    SET_BUILDING_TYPES(state, building_types) {
      state.building_types = building_types;
    },
    SET_BUILDING_PROGRESS(state, building_progress) {
      state.building_progress = building_progress;
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages;
    },
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
    },
    SET_PAGES(state, pages) {
      state.pages = pages;
    },
    SET_NOTIFICATION_COUNT(state, notification_count) {
      state.notification_count = notification_count;
    },
  },
  getters: {
    selectedBuilding: (state) => state.selectedBuilding,
    token: (state) => state.token,
    user: (state) => state.user,
    buildings: (state) => state.buildings,
    decarb_buildings: (state) => state.decarb_buildings,
    building_types: (state) => state.building_types,
    building_progress: (state) => state.building_progress,
    messages: (state) => state.messages,
    loaded: (state) => state.loaded,
    pages: (state) => state.pages,
    notification_count: (state) => state.notification_count,
    abb: (state) => state.isAbbUser,
  },
  actions: {
    get_token({ commit }, { auth0 }) {
      return new Promise((resolve, reject) => {
        if (auth0.isAuthenticated) {
          auth0
            .getAccessTokenSilently()
            .then((authToken) => {
              commit("SET_TOKEN", authToken);
              resolve(authToken);
            })
            .catch((err) => reject(err));
        }
      });
    },
    // works with async / await
    async get_user_data({ commit }, { auth0 }) {
      const { email } = auth0.user.value;

      return await apolloClient
        .query({
          query: QUERY_USER_BY_EMAIL,
          variables: { email },
        })
        .then(({ data: { get_user_by_email } }) => {
          const clients = get_user_by_email.user_clients.map(
            (uc) => uc.client_id,
          );

          const buildings = get_user_by_email.user_buildings.map((ub) => {
            return {
              id: ub.building_id,
              permissions:
                ub.building_permissions.length === 0
                  ? []
                  : ub.building_permissions.map(
                      ({ permission: { scope } }) => scope,
                    ),
            };
          });

          commit("SET_USER", {
            ...get_user_by_email,
            buildings,
            clients,
          });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    async get_decarb_buildings({ state, commit }, payload) {
      const { clients } = state.user;

      if (clients.length) {
        return await apolloClient
          .query({
            query: QUERY_DECARB_BUILDINGS,
            variables: {
              client_ids: clients,
            },
          })
          .then(({ data: { get_decarb_buildings } }) => {
            if (get_decarb_buildings) {
              let theDecarbBuildings = get_decarb_buildings.map((dcb) => ({
                ...dcb,
                isDecarb: true,
              }));

              if (payload) {
                if (Array.isArray(payload)) {
                  theDecarbBuildings = [...theDecarbBuildings, ...payload];
                } else if (!isNaN(+payload)) {
                  //this should mean payload is an id - so we want to remove that id from data
                  theDecarbBuildings = [...theDecarbBuildings].filter(
                    (dcb) => dcb.id !== payload,
                  );
                } else {
                  theDecarbBuildings = [...theDecarbBuildings, payload];
                }
              }

              commit("SET_DECARB_BUILDINGS", theDecarbBuildings);
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
    },

    async get_buildings({ state, commit }) {
      const { buildings, permissions } = state.user;

      if (buildings.length === 0) return [];

      return await apolloClient
        .query({
          query: QUERY_BUILDINGS_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            building_ids: buildings.map((b) => b.id),
            is_anonymous: permissions.includes("read:anonymous"),
          },
        })
        .then(({ data: { get_buildings_by_id } }) => {
          //must be an update with apollo - can't call sort directly on the data returned. That is why we map first
          const sorted_buildings = permissions.includes("read:anonymous")
            ? get_buildings_by_id
            : get_buildings_by_id
                .map((b) => b)
                .sort((a, b) => a.building_name.localeCompare(b.building_name));

          commit("SET_BUILDINGS", sorted_buildings);
        });
    },
    async get_building_details({ state, commit }) {
      return await apolloClient
        .query({
          query: QUERY_DEV_ENUMERATIONS,
          fetchPolicy: "network-only",
          variables: {
            table: "buildings",
          },
        })
        .then(({ data: { get_dev_enumerations } }) => {
          commit(
            "SET_BUILDING_TYPES",
            get_dev_enumerations.filter((e) => e.columns === "type"),
          );
          commit(
            "SET_BUILDING_PROGRESS",
            get_dev_enumerations.filter((e) => e.columns === "progress"),
          );
        });
    },
    async get_messages({ state, commit }) {
      const { buildings } = state.user;
      if (buildings.length === 0) return [];

      return await apolloClient
        .query({
          query: QUERY_MESSAGES,
          fetchPolicy: "network-only",
        })
        .then(({ data: { get_messages } }) =>
          commit("SET_MESSAGES", get_messages),
        );
    },
    set_pages({ state, commit }) {
      const { permissions } = state.user;
      const items = ["read:portfolio", "read:buildings"];
      let pages = [];
      items.forEach((item) => {
        if (permissions.includes(item)) {
          pages.push(item.split(":")[1]);
        }
      });
      pages.push("profile");
      commit("SET_PAGES", pages);
    },
    async get_read_user_notifications({ state }) {
      const { email } = state.user;
      return await apolloClient
        .query({
          query: QUERY_NOTIFICATION,
          fetchPolicy: "network-only",
          variables: {
            user: email,
          },
        })
        .then(({ data: { get_notification } }) => {
          if (get_notification && get_notification.read_notifications) {
            this.read_notifications =
              get_notification.read_notifications.split(",");
          }
        });
    },
    async get_notification_count({ commit, dispatch }) {
      let items = null;
      let releases = [];
      return await apolloClient
        .query({
          query: QUERY_FEATURE_RELEASES,
          fetchPolicy: "network-only",
          variables: {
            type: "active",
          },
        })
        .then(({ data: { get_feature_releases } }) => {
          items = get_feature_releases;
          if (items.length > 0) {
            items
              .map((item) => item)
              .sort(
                (a, b) => new Date(b.release_date) - new Date(a.release_date),
              );
            localStorage.setItem("featureReleases", JSON.stringify(items));
            let releaseID = items[0].release_number;
            releases.push({
              release: releaseID,
              release_date: items[0].release_date,
              isExpand: false,
            });
            for (let i = 0; i < items.length; i++) {
              if (items[i].release_number !== releaseID) {
                releaseID = items[i].release_number;
                releases.push({
                  release: items[i].release_number,
                  release_date: items[i].release_date,
                  isExpand: false,
                });
              }
            }
            releases.sort(
              (a, b) => new Date(b.release_date) - new Date(a.release_date),
            );
            dispatch("get_read_user_notifications").then(() => {
              let notificationsCount = 0;
              if (this.read_notifications) {
                releases.forEach((item) => {
                  if (!this.read_notifications.includes(item.release)) {
                    notificationsCount = notificationsCount + 1;
                  }
                });
              } else {
                notificationsCount = releases.length;
              }

              commit("SET_NOTIFICATION_COUNT", notificationsCount);
            });
          } else {
            localStorage.removeItem("featureReleases");
          }
        });
    },
  },
  modules: {},
});
