<template>
    <transition name="popup-fade">
      <div class="popup-backdrop">
        <div class="popup" role="dialog" aria-labelledby="popupTitle" aria-describedby="popupDescription">
            <header class="popup-header flex flex-col items-center bg-red-500 text-white" id="popupTitle">
                <h1 class="font-bold uppercase">CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL</h1>
                <span class="text-base mt-2">* English version will follow *</span>
            </header>
            <section class="flex flex-col popup-body max-w-4xl" id="popupDescription">
                <p class="font-bold my-6">Version du 5 avril 2023</p>
                <div class="contentSction font-base mb-4">
                    <p>
                
                        IMPORTANT : LE PROPRIÉTAIRE DU TABLEAU DE BORD, BRAINBOX AI, VOUS PERMET D’UTILISER LE TABLEAU DE BORD UNIQUEMENT SI VOUS ACCEPTEZ TOUTES LES CONDITIONS GÉNÉRALES CONTENUES DANS LES PRÉSENTES CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL (« <strong>Conditions</strong> »).
                        
                    </p>
                </div>
                <ol class="eutu space-y-4 list-decimal list-inside" type="1">
                    <li>
                        LES SERVICES BRAINBOX AI ET LE CONTRAT BRAINBOX AI SERVICES 
                        <p>Brainbox AI Inc., ayant une place d’affaires au 2075, boul. Robert Bourassa, bureau 500, Montréal, Québec, Canada, ou une de ses sociétés affiliées (« <strong>Brainbox AI</strong> »), a accepté de fournir à votre organisation un service infonuagique utilisant une technologie autonome basée sur l’intelligence artificielle pour l’optimisation de la consommation d’énergie des systèmes de chauffage, de ventilation et de climatisation (« CVC ») (les « <strong>Services Brainbox AI</strong> »).</p>
                        <p>Brainbox AI fourni directement les Services Brainbox AI et, dans certains cas, elle a accordé à des revendeurs ou distributeurs le droit de vendre les Services Brainbox AI (les « <strong>Partenaires</strong> »).</p>
                        <p>Ce tableau de bord est offert en ligne via un navigateur Web, afin de vous permettre de visualiser les renseignements relatifs au rendement des Services Brainbox AI (le « <strong>Tableau de bord </strong>»). Brainbox AI se réserve le droit de modifier le Tableau de bord, ou ses caractéristiques ou fonctionnalités, ou d’interrompre de façon générale l’accès au Tableau de bord, à tout moment et sans préavis.</p>
                        <p>Les Services Brainbox AI et le Tableau de bord sont décrits plus en détail et sont fournis conformément aux Termes d’utilisation publiés sur le site Web de Brainbox AI à <a target="_blank" href="https://brainboxai.com/fr/terms-of-service">https://brainboxai.com/fr/terms-of-service</a> ou sur le site Web du Partenaire, le cas échéant.</p>
                        <p>Si vous avez obtenu l’accès à ce Tableau de bord, votre organisation et Brainbox AI, ou l’un de ses Partenaires, ont signé une entente pour l’achat par votre organisation des Services Brainbox AI (le « <strong>Contrat de Services Brainbox AI</strong> »). </p>
                        
                    </li>
                    <li>
                        DROIT D’UTILISATION
                        <p>
                            Les présentes Conditions vous accordent le droit d’utiliser le Tableau de bord, uniquement que conformément aux termes et conditions des présentes Conditions et du Contrat de Services Brainbox AI. 
                        </p>
                        <p>
                            Votre droit d’utiliser le Tableau de bord est conditionnel à: (a) la validité du Contrat de Services Brainbox AI; et (b) la non-suspension des Services Brainbox AI par Brainbox AI ou un Partenaire en vertu des termes du Contrat de Services Brainbox AI; et (c) vous avez été désigné à titre d’utilisateur autorisé par votre organisation ; et (d) votre utilisation du Tableau de bord conformément aux présentes Conditions.
                        </p>
                        <p>
                            Vous êtes seul responsable de votre utilisation du Tableau de bord et de votre usage des renseignements qui y sont contenus.
                        </p>
                        <p>
                            Brainbox AI et ses partenaires se réservent le droit de surveiller votre utilisation du Tableau de bord afin de: (a) s’assurer que vous ne violez pas les présentes Conditions, ou les lois et réglementations applicables; et (b) collecter des données transactionnelles et de performance liées à l’utilisation générale du Tableau de bord à des fins d’optimisation. Ces données collectées sont anonymisées et ne seront pas identifiables personnellement.
                        </p>
                        <p>
                            Vous acceptez d’indemniser, de défendre et de dégager de toute responsabilité Brainbox AI et ses Partenaires, leurs dirigeants, administrateurs, employés, agents et concédants de licence contre toute réclamation, responsabilité, dommage, perte, coût et dépense (y compris les honoraires raisonnables d’avocat) découlant de ou en relation avec votre utilisation du Tableau de bord.
                        </p>

                    </li>
                    <li>
                        CONFIGURATION REQUISE
                        <p>
                            Vous êtes responsable de vous assurer que les systèmes informatiques sur lesquels le Tableau de bord doit être consulté ainsi que le système de télécommunication utilisé pour établir la connexion répondent aux exigences minimales prescrites par Brainbox AI.
                        </p>
                    </li>
                    <li>
                        CONNEXION
                        <p>
                            En tant qu’utilisateur autorisé du Tableau de bord, vous devrez créer un compte.
                        </p>
                        <p>
                            On vous demandera de fournir certaines informations de contact professionnel afin que Brainbox AI ou ses Partenaires puissent vous envoyer les informations d’accès de connexion. Vous recevrez alors un identifiant de connexion vous donnant accès au Tableau de bord. Dans le cadre du processus de configuration, vous devrez d’abord créer un mot de passe, puis configurer l’authentification multi-facteur.
                        </p>
                        <p>
                            Vous êtes seul responsable de fournir des coordonnées professionnelles exactes et de garder les informations de connexion à votre compte confidentielles et sécurisées.
                        </p>
                        <p>
                            Une fois votre compte configuré, vous êtes seul responsable de toute activité qui se produit sous votre compte.
                        </p>
                    </li>
                    <li>
                        RESTRICTIONS
                        <p>Vous vous engager à ne pas:</p>
                        <ol class="restrictionList list-inside" type="a">
                            <li>vendre, louer, concéder sous licence, sous-licencier, prêter, grever ou autrement transférer à un tiers le Tableau de bord, en tout ou en partie ;</li>
                            <li>décompiler, désassembler, faire de l’ingénierie inverse ou tenter de toute autre manière de dériver le code source de tout ou partie du Tableau de bord;</li>
                            <li>apporter des modifications, des corrections, des altérations, des améliorations ou d’autres ajouts au Tableau de bord;</li>
                            <li>mettre le Tableau de bord à la disposition d’une personne qui n’est pas autorisé par votre organisation ou d’un tiers par le biais de services en ligne, de connexion à distance ou de liens réseau ou de télécommunication de quelque nature que ce soit ;</li>
                            <li>utiliser le Tableau de bord à des fins illégales ou l’utiliser pour se livrer à une activité illégale, frauduleuse ou non autorisée ;</li>
                            <li>utiliser le Tableau de bord en présence d’un tiers qui, de l’avis de Brainbox AI, est un concurrent de Brainbox AI ; et</li>
                            <li>fournir, divulguer, divulguer ou mettre à disposition, ou permettre l’utilisation du Tableau de bord par des personnes autres que vous-même.</li>
                        </ol>
                        <p>Les présents Conditions ne vous accordent, ni à quiconque, le droit d’accéder au code source du Tableau de bord ou d’obtenir celui-ci.</p>
                    </li>
                    <li>
                        PROPRIÉTÉ ET LIMITES D’UTILISATION
                        <p>
                            Le Tableau de bord, y compris, mais sans s’y limiter, les logiciels, graphiques, textes, images et autres contenus, est la propriété de Brainbox AI ou de ses concédants de licence et est protégé par les lois et règlements sur la propriété intellectuelle.
                        </p>
                        <p>
                            Vous reconnaissez et acceptez que toutes les marques de commerce, inventions (brevetables ou non), demandes de brevet, brevets, œuvres protégées par des droits d’auteur, secrets commerciaux, savoir-faire ou autre propriété intellectuelle dans ou liés au Tableau de bord et les logiciels, graphiques, textes, images et autres contenus sont la propriété exclusive de Brainbox AI, ou de ses concédants de licence, ou sont autrement contrôlés par Brainbox AI.
                        </p>
                        <p>
                            Vous ne devez prendre aucune mesure qui conteste la propriété intellectuelle de Brainbox AI, y compris, mais sans s’y limiter, les actions en nullité intentées contre les brevets détenus par Brainbox AI.
                        </p>
                        <p>
                            Tous les droits, titres et intérêts qui ne sont pas expressément énoncés dans les présentes Conditions sont expressément réservés par Brainbox AI.
                        </p>
                    </li>
                    <li>
                        CONFIDENTIALITÉ
                        <p>Vous reconnaissez que toutes les informations relatives au Tableau de bord ou que vous avez obtenues ou obtiendrez suite à l’utilisation du Tableau de bord sont de nature confidentielle et sont couverts par des droits d’auteur appartenant exclusivement à Brainbox AI. Vous vous engagez à mettre en œuvre des mesures de sécurité raisonnables pour protéger ces renseignements et conserver ces informations confidentielles sans les divulguer à des tiers.</p>
                        <p>Vous acceptez que nous puissions recueillir, utiliser et partager vos renseignements personnels conformément à notre politique de confidentialité, qui se trouve à <a target="_blank" href="https://brainboxai.com/fr/confidentialite">https://brainboxai.com/fr/confidentialite</a> , que vous avez examinée et acceptée.</p>
                    </li>
                    <li>
                        EXCLUSION D’EXACTITUDE ET D’EXHAUSTIVITÉ DES INFORMATIONS 
                        <p>
                            Aucune garantie n’est fournie quant à l’exactitude et à l’exhaustivité des renseignements contenus sur le Tableau de bord. Vous reconnaissez que les produits et services de Brainbox AI sont destinés à vous aider à améliorer l’efficacité énergétique, mais que Brainbox AI et ses Partenaires ne peuvent garantir des performances ou des résultats spécifiques, et que vos économies d’énergie réelles peuvent varier en fonction de votre situation spécifique.
                        </p>
                        <p>
                            L’exactitude des informations contenues dans le Tableau de bord dépend de l’exactitude et de l’exhaustivité des Données du Client (ce terme est défini dans le Contrat de Services Brainbox AI, plus précisément dans les Termes d’utilisation) fournies par vous ou votre organisation conformément au Contrat de Services Brainbox AI afin que Brainbox AI puisse vous fournir les Services Brainbox AI.
                        </p>
                        <p>
                            Les données de mesure et de vérification des économies d’énergie dans les bâtiments affichés sur le Tableau de bord sont basées sur la méthodologie normalisée développée par l’American Society of Heating, Refrigerating and Air-Conditioning Engineers (ASHRAE) et le protocole international de mesure et de vérification de la performance (IPMVP®). L’estimation des économies d’énergie et d’émissions par bâtiment est fondée sur un certain nombre d’hypothèses et de variables qui peuvent différer des conditions réelles.
                        </p>
                    </li>
                    <li>
                        EXCLUSION DE GARANTIE
                        <p>
                            LE TABLEAU DE BORD EST FOURNI « TEL QUEL ». BRAINBOX AI ET SES PARTENAIRES NE GARANTISSENT PAS QUE LE TABLEAU DE BORD OU LES INFORMATIONS QU’IL CONTIENT SONT EXEMPTS D’ERREURS OU QUE VOUS POURREZ UTILISER LE TABLEAU DE BORD SANS PROBLÈMES NI INTERRUPTIONS, OU QU’IL SERA EXEMPT DE VULNÉRABILITÉ AUX INTRUSIONS OU AUX ATTAQUES. BRAINBOX AI ET SES PARTENAIRES DÉCLINENT EXPRESSÉMENT TOUTE GARANTIE, REPRÉSENTATION ET CONDITION, EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS S’Y LIMITER, LES GARANTIES D’ABSENCE DE CONTREFAÇON, DE QUALITÉ MARCHANDE, D’ADÉQUATION À UN USAGE PARTICULIER, DE QUALITÉ SATISFAISANTE ET/OU DÉCOULANT D’UNE TRANSACTION, D’UN USAGE OU D’UNE PRATIQUE COMMERCIALE EN CE QUI CONCERNE LE TABLEAU DE BORD OU LES INFORMATIONS CONTENUES SUR LE TABLEAU DE BORD.
                        </p>
                        <p>
                            COMME CERTAINES JURIDICTIONS NE PERMETTENT PAS L’EXCLUSION OU LA LIMITATION DES GARANTIES IMPLICITES, LA LIMITATION CI-DESSUS PEUT NE PAS S’APPLIQUER À VOUS. DANS LA MESURE OÙ UNE GARANTIE IMPLICITE NE PEUT ÊTRE EXCLUE, CETTE GARANTIE EST LIMITÉE À LA GARANTIE LA PLUS STRICTE APPLICABLE DANS CETTE JURIDICTION.
                        </p>
                    </li>
                    <li>
                        LIMITATION DE RESPONSABILITÉ
                        <p>
                            EN AUCUN CAS BRAINBOX AI ET SES PARTENAIRES, AINSI QUE LEURS EMPLOYÉS, ADMINISTRATEURS, DIRIGEANTS ET AGENTS NE POURRONT ÊTRE TENUS COLLECTIVEMENT RESPONSABLES ENVERS VOUS POUR DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT. TOUTE INDEMNITÉ DÉCOULANT DE OU EN RELATION AVEC VOTRE UTILISATION DU TABLEAU DE BORD OU VOTRE UTILISATION DE TOUT RENSEIGNEMENT CONTENU DANS LE TABLEAU DE BORD RÉCLAMÉE EN VERTU DE TOUT CONTRAT, ÉQUITÉ, DÉLIT (Y COMPRIS LA NÉGLIGENCE OU LA RESPONSABILITÉ STRICTE), GARANTIE OU AUTRE, EST LIMITÉE À 100 $ AU TOTAL OU LE REMPLACEMENT OU LA RÉPARATION DU TABLEAU DE BORD (À LA SEULE DISCRÉTION DE BRAINBOX AI).
                        </p>
                        <p>
                            EN AUCUN CAS BRAINBOX AI ET SES PARTENAIRES, AINSI QUE LEURS EMPLOYÉS, ADMINISTRATEURS, DIRIGEANTS ET AGENTS NE POURRONT ÊTRE TENUS RESPONSABLES ENVERS VOUS DE TOUT DOMMAGE SPÉCIAL, ACCESSOIRE, INDIRECT OU CONSÉCUTIF DE QUELQUE NATURE QUE CE SOIT, OU DE LA PERTE DE PROFIT DÉCOULANT DE OU EN RELATION AVEC L’UTILISATION DU TABLEAU DE BORD OU VOTRE UTILISATION DE TOUT RENSEIGNEMENT CONTENU SUR LE TABLEAU DE BORD, MÊME SI BRAINBOX AI OU SES PARTENAIRES SONT INFORMÉS DE LA POSSIBILITÉ D’UNE TELLE PERTE OU DOMMAGE POTENTIEL.
                        </p>
                    </li>
                    <li>
                        TERMINAISON
                        <p>
                            Vous comprenez et acceptez que Brainbox AI ou ses Partenaires puissent résilier votre accès au Tableau de bord, annuler votre compte, et tous vos droits en vertu des présentes Conditions seront automatiquement résiliés si : (a) votre organisation n’a plus le droit de vous permettre d’utiliser le Tableau de bord; ou (b) vous enfreignez les termes des présentes Conditions, ou les lois et réglementations applicables; ou (c) vous n’êtes plus un utilisateur autorisé désigné par votre organisation.
                        </p>
                        <p>
                            En cas de résiliation de vos privilèges d’accès, vous acceptez de cesser d’utiliser le Tableau de bord et de détruire immédiatement toutes les copies que vous pourriez avoir faites.
                        </p>
                    </li>
                    <li>
                        LOI APPLICABLE ET JURIDICTION
                        <p>
                            Les présentes Conditions sont régies et interprétées conformément aux lois en vigueur dans la province de Québec, Canada, sans donner effet aux principes de conflits de lois. Les tribunaux ayant compétence dans et pour le district de Montréal dans la province de Québec auront compétence exclusive à l’égard de tout différend lié à la présente entente. 
                        </p>
                    </li>
                    <li>
                        AMENDEMENTS
                        <p>
                            Brainbox AI et ses Partners peuvent modifier ces Conditions ou toute section de celles-ci, en tout ou en partie, à tout moment.
                        </p>
                        <p>
                            En cas de modification, vous serez invité à accepter les Conditions d’utilisation de l’utilisateur final modifiées lors de votre prochain accès au Tableau de bord.
                        </p>
                    </li>
                </ol>
            </section>

            <header class="popup-header justify-center bg-red-500 text-white" id="popupTitle">
                <h1 class="font-bold uppercase">End-User Terms of Use</h1>
            </header>
    
            <section class="flex flex-col popup-body max-w-4xl" id="popupDescription">
                <p class="font-bold my-6">Version of April 5, 2023</p>
                <div class="contentSction font-base mb-4">
                    <p class="uppercase">
                        <span>
                            IMPORTANT: THE OWNER OF THIS DASHBOARD, BRAINBOX AI, ALLOWS YOU TO USE THE DASHBOARD ONLY IF YOU ACCEPT ALL OF THE TERMS AND CONDITIONS CONTAINED IN THESE END-USER TERMS OF USE (“<strong>EUTU</strong>”).
                        </span>
                    </p>
                </div>
                <ol class="eutu space-y-4 list-decimal list-inside" type="1">
                    <li>
                        BRAINBOX AI SERVICES AND BRAINBOX AI SERVICES AGREEMENT
                        <p>Brainbox AI Inc., having a place of business at 2075 Robert Bourassa Blvd, suite 500, Montreal, Quebec, Canada, or any of its affiliated company (“<strong>Brainbox AI</strong>”), has agreed to provide to your organization a cloud-based service using an autonomous artificial intelligence-based technology for the optimization of the energy consumption of your organization’s Heating, Ventilation and Air-conditioning (“HVAC”) systems (the “<strong>Brainbox AI Services</strong>”).</p>
                        <p>Brainbox AI provides the Brainbox AI Services directly, and in some cases, it has granted various resellers or distributors the right to sell the Brainbox AI Services (the “<strong>Partners</strong>”).</p>
                        <p>This online dashboard is offered via an internet browser, to allow you to view the performance-related information of the Brainbox AI Services (the “<strong>Dashboard</strong>”). Brainbox AI reserves the right to modify the Dashboard, and the features or functionality thereof, or discontinue generally the access to its Dashboard, at any time without notice.</p>
                        <p>The Brainbox AI Services and the Dashboard are more fully described in, and shall be provided in accordance with, the Terms of Service posted on the Brainbox AI website at <a target="_blank" href="https://brainboxai.com/en/terms-of-service">https://brainboxai.com/en/terms-of-service</a> or the Partner’s website, as applicable.</p>
                        <p>If you have been granted access to this Dashboard, your organization and Brainbox AI, or one of its Partners, have signed an agreement for the purchase by your organization of the Brainbox AI Services (the “<strong>Brainbox AI Services Agreement</strong>”).</p>
                    </li>
                    <li>
                        RIGHT TO USE
                        <p>
                            These EUTU grant you the right to use the Dashboard, but only in compliance with these EUTU and the terms and conditions of the Brainbox AI Services Agreement.
                        </p>
                        <p>
                            Your right to use the Dashboard is conditional upon: (a) the Brainbox AI Services Agreement being valid and in full force and effect; and (b) the Brainbox AI Services not having been suspended by Brainbox AI or a Partner under the terms of the Brainbox AI Services Agreement; and (c) you have been designated as an authorized user by your organization; and (d) your use of the Dashboard in compliance with these EUTU.
                        </p>
                        <p>
                            You are solely responsible for your use of the Dashboard and your use of the information contained therein.
                        </p>
                        <p>
                            Brainbox AI and its Partners reserve the right to monitor your use of the Dashboard in order to: (a) ensure that you are not violating these EUTU, or applicable laws and regulations; and (b) collect transactional and performance data related to the general use of the Dashboard for optimization purposes. This data is anonymized and will not be personally identifiable.
                        </p>
                        <p>
                            You agree to indemnify, defend, and hold harmless Brainbox AI and its Partners, their officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Dashboard.
                        </p>
                    </li>
                    <li>
                        SYSTEM REQUIREMENTS
                        <p>
                            You are responsible for ensuring that the computer systems upon which the Dashboard shall be accessed as well as the telecommunication system used to establish connection meet the minimal requirements prescribed by Brainbox AI.
                        </p>
                    </li>
                    <li>
                        LOGIN
                        <p>
                            As an authorized user of the Dashboard, you will be asked to create an account.
                        </p>
                        <p>
                            You will need to provide certain business contact information in order for Brainbox AI or its Partners to send you the login access information. You will then receive a login ID giving you access to the Dashboard. As part of the set-up process, you will be required to create a password and to set up multi-factor authentication.
                        </p>
                        <p>
                            You are solely responsible to provide accurate business contact information and to keep your account login information confidential and secure.
                        </p>
                        <p>
                            Once your account is set up, you are solely responsible for any activity that occurs under your account.
                        </p>
                    </li>
                    <li>
                        RESTRICTIONS
                        <p>You warrant that you will not:</p>
                        <ol class="restrictionList list-inside" type="a">
                            <li>sell, lease, license, sublicense, loan, encumber or otherwise transfer to a third party the Dashboard, in whole or in part;</li>
                            <li>decompile, disassemble, reverse engineer or otherwise attempt to derive the source code of the whole or any portion of the Dashboard;</li>
                            <li>make modifications, corrections, alterations, enhancements or other additions to the Dashboard;</li>
                            <li>make the Dashboard available to a person that is not authorized by you organization or to a third party by online services, remote dial-in or network or telecommunication links of any kind;</li>
                            <li>use the Dashboard for any unlawful purposes, or use it to engage in any illegal, fraudulent, or unauthorized activity;</li>
                            <li>use the Dashboard in the presence of a third party who, in the opinion of Brainbox AI, is a competitor of Brainbox AI; and</li>
                            <li>provide, disclose, divulge or make available to, or permit use of the Dashboard by persons other than yourself.</li>
                        </ol>
                        <p>
                            These EUTU do not grant you or anyone else the right to access or obtain the source code of the Dashboard.
                        </p>
                    </li>
                    <li>
                        OWNERSHIP AND LIMITS TO USE
                        <p>
                            The Dashboard, including but not limited to software, graphics, text, images, and other content, are owned by Brainbox AI or its licensors and are protected by intellectual property laws and regulations.
                        </p>
                        <p>
                            You recognize and agree that all trademarks, inventions (whether patentable or not), patent applications, patents, works protected by copyright, trade secrets, know-how or other intellectual property in or related to the Dashboard and to any software, graphics, text, images, and other content are the exclusive property of Brainbox AI, or its licensors, and are controlled by Brainbox AI.
                        </p>
                        <p>
                            You shall not take any action that challenges the intellectual property of Brainbox AI, including but not limited to invalidity actions brought against patents owned by Brainbox AI.
                        </p>
                        <p>
                            All rights, title and interests that are not expressly set out in these EUTU are expressly reserved by Brainbox AI. 
                        </p>
                    </li>
                    <li>
                        CONFIDENTIALITY
                        <p>You acknowledge that all information relating to the Dashboard or that you have obtained or will obtain from the use of the Dashboard are of a confidential nature and are subject to copyright owned by Brainbox AI. You agree to implement reasonable security measures to protect such information and to hold such information in confidence without disclosing it to any third party.</p>
                        <p>You agree that Brainbox AI may collect, use, and share your personal information in accordance with Brainbox AI’s privacy policy, found at <a target="_blank" href="https://brainboxai.com/en/privacy-policy">https://brainboxai.com/en/privacy-policy</a>, which you have reviewed and agreed to.</p>
                    </li>
                    <li>
                        DISCLAIMER OF ACCURACY AND COMPLETENESS OF INFORMATION
                        <p>
                            No warranty is provided as to the accuracy and completeness of the information contained on the Dashboard. You acknowledge that Brainbox AI’s products and services are intended to help you improve energy efficiency, but that Brainbox AI and its Partners cannot guarantee specific results or outcomes, and that your actual energy savings may vary depending on your specific circumstances.
                        </p>
                        <p>
                            The accuracy of the information contained on the Dashboard depends on the accuracy and completeness of the Customer Data (this term is defined in the Brainbox AI Services Agreement, more specifically in the Terms of Service) provided by you or your organization pursuant to the Brainbox AI Services Agreement for Brainbox AI to provide the Brainbox AI Services. 
                        </p>
                        <p>
                            The Measurement and Verification of energy savings in buildings results displayed on the Dashboard are based upon the standardized methodology developed by the American Society of Heating, Refrigerating and Air-Conditioning Engineers (ASHRAE) and the International Performance Measurement and Verification Protocol (IPMVP®). The estimated energy and emissions savings per building is based on a number of assumptions and variables that may differ from actual conditions.
                        </p>
                    </li>
                    <li>
                        DISCLAIMER OF WARRANTY
                        <p>
                            THE DASHBOARD IS PROVIDED "AS IS". BRAINBOX AI AND ITS PARTNERS DO NOT WARRANT THAT THE DASHBOARD OR THE INFORMATION CONTAINED ON THE DASHBOARD IS ERROR FREE OR THAT YOU WILL BE ABLE TO OPERATE THE DASHBOARD WITHOUT PROBLEMS OR INTERRUPTIONS, OR THAT IT WILL BE FREE OF VULNERABILITY TO INTRUSION OR ATTACK. BRAINBOX AI AND ITS PARTNERS EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY AND/OR ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE WITH REGARD TO THE DASHBOARD OR THE INFORMATION CONTAINED ON THE DASHBOARD.
                        </p>
                        <p>
                            BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. TO THE EXTENT AN IMPLIED WARRANTY CANNOT BE EXCLUDED, SUCH WARRANTY IS LIMITED TO THE STRICTEST WARRANTY APPLICABLE IN SUCH JURISDICTION.
                        </p>
                    </li>
                    <li>
                        LIMITATION OF LIABILITY
                        <p>
                            IN NO EVENT SHALL BRAINBOX AI, ITS PARTNERS AND THEIR EMPLOYEES, DIRECTORS, OFFICERS AND AGENTS TOTAL COLLECTIVE LIABILITY TO YOU FOR DAMAGES OF ANY KIND. ANY INDEMNITY ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE DASHBOARD OR YOUR USE OF ANY INFORMATION CONTAINED ON THE DASHBOARD CLAIMED IN CONTRACT, EQUITY, TORT, (INCLUDING NEGLIGENCE OR STRICT LIABILITY), WARRANTY OR OTHERWISE, SHALL BE LIMITED TO $100 IN THE AGGREGATE OR REPLACEMENT OR REPAIR OF THE DASHBOARD (AT BRAINBOX AI’S SOLE OPTION).
                        </p>
                        <p>
                            IN NO EVENT SHALL BRAINBOX AI, ITS PARTNERS AND THEIR EMPLOYEES, DIRECTORS, OFFICERS AND AGENTS BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR FOR THE LOSS OF PROFIT ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE DASHBOARD OR YOUR USE OF ANY INFORMATION CONTAINED ON THE DASHBOARD, EVEN IF BRAINBOX AI OR ITS PARTNERS ARE ADVISED OF THE POSSIBILITY OF SUCH POTENTIAL LOSS OR DAMAGE.
                        </p>
                    </li>
                    <li>
                        TERMINATION
                        <p>
                            You understand and agree that Brainbox AI or its Partners may terminate your access to the Dashboard, cancel your account, and all of your rights under these EUTU will terminate automatically, if: (a) your organization no long holds the right to allow you to use the Dashboard; or (b) you breach the terms of these EUTU, or applicable laws and regulations; or (c) you are no longer an authorized user designated by your organization.
                        </p>
                        <p>
                            Upon termination of your access privileges, you agree to cease to use the Dashboard and destroy immediately all copies that you may have made.
                        </p>
                    </li>
                    <li>
                        GOVERNING LAW AND JURISDICTION
                        <p>
                            These EUTU shall be governed and construed in accordance with the laws in force in the Province of Quebec, Canada, without giving effect to principles of conflicts of law. The courts having jurisdiction in and for the district of Montreal in the Province of Quebec shall have exclusive jurisdiction in respect of any dispute related to this Agreement. 
                        </p>
                    </li>
                    <li>
                        AMENDMENTS
                        <p>
                            Brainbox AI and its Partners may amend these EUTUs or any section thereof, in whole or in part, at any time. In the event of a change, you will be prompted to accept the modified EUTU when next accessing the Dashboard.
                        </p>
                    </li>
                    <li>
                        LANGUAGE
                        <p>
                            A French version of these EUTU precedes the English version.
                        </p>
                    </li>
                    <li>
                        ACCEPTANCE/ACCEPTATION
                        <p>
                            BY CLICKING THE "I ACCEPT" BUTTON DURING THE LOGIN PROCESS TO ACCESS THE DASHBOARD, OR BY SIMPLY USING THE DASHBOARD, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE END-USER TERMS OF USE AND YOU ACCEPT AND AGREE TO BE BOUND BY THE THEM, MORE PARTICULARLY, THE APPLICABLE LIMITATION OF LIABILITY AT SECTION 10.
                        </p>
                        <p>
                            YOU IF YOU DO NOT AGREE WITH THESE END-USER TERMS OF USE, YOU SHOULD NOT ACCESS OR USE THE DASHBOARD.
                        </p>
                        <p>
                            EN CLIQUANT SUR LE BOUTON « J’ACCEPTE » CI-DESSOUS, ET EN UTILISANT SIMPLEMENT LE TABLEAU DE BORD, VOUS RECONNAISSEZ AVOIR LU LES PRÉSENTS CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL ET VOUS ACCEPTEZ D’Y ÊTRE LIÉ, PLUS PARTICULIÈREMENT LES LIMIES DE RESPONSABILITÉ AU PARAGRAPHE 10.
                        </p>
                        <p>
                            SI VOUS N’ÊTES PAS D’ACCORD, VOUS NE DEVEZ PAS ACCÉDER AU TABLEAU DE BORD NI L’UTILISER.
                        </p>
                    </li>
                </ol>
                <div class="acknowledgeSection py-8">
                    <p>YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ THESE EUTU, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEIR TERMS AND CONDITIONS.</p>
                    <div class="flex justify-center">
                        <button @click="close" class="mt-6 mr-4 bg-white text-black hover:bg-bbred-500 hover:text-white p-2 border border-gray-200 shadow-lg rounded-md">
                            {{ $t('agree') }}
                        </button>
                        <button @click="logout" class="mt-6 bg-white text-black hover:bg-bbred-500 hover:text-white p-2 border border-gray-200 shadow-lg rounded-md">
                            {{ $t('disagree') }}
                        </button>
                    </div>
                </div>
          </section>
        </div>
      </div>
    </transition>
  </template>
  
<script>
export default {
    name: 'TermOfUsePopup',
    methods: {
        logout() {
            this
                .$auth0
                .logout({returnTo: window.location.origin});
        },
        close() {
            this.$emit('close')
            this.scrollToTop()
            localStorage.setItem('isShowPopup', 'false');
            console.log(localStorage.getItem('isShowPopup'))
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
};
</script>
  
  <style>
    .popup-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(246, 246, 246, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }
  
    .popup {
      background: #f6f6f6;
      box-shadow: 1px 1px 10px 1px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      max-height: 75%;
    }
  
    .popup-header {
      padding: 30px;
      display: flex;
    }
  
    .popup-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: #000;
      font-weight: 600;
      font-size: 20px;
      justify-content: space-between;
    }
  
    .popup-body {
      color: black;
      position: relative;
      padding: 20px 30px;
    }
  
    .eutu li {
    display: list-item;
}

    .eutu li::before {
        content: "";
        width: 30px;
        display: inline-block;
    }
    .eutu p{
        margin-top: 1rem;
    }
    .eutu a {
        color: revert;
        text-decoration: underline;
    }
    .restrictionList {
        list-style: lower-alpha;
        margin-left: 20px;
    }
    .restrictionList li::before {
        content: "";
        width: 30px;
        display: inline-block;
    }
  
    .btn-red {
      color: white;
      background: #e40e20;
      border: 1px solid #e40e20;
      border-radius: 5px;
    }
  
    .popup-fade-enter,
    .popup-fade-leave-to {
      opacity: 0;
    }
  
    .popup-fade-enter-active,
    .popup-fade-leave-active {
      transition: opacity .5s ease;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    /* .popup::-webkit-scrollbar {
        display: none;
    } */
    /* Hide scrollbar for IE, Edge and Firefox */
    /* IE and Edge *//* Firefox */
    /* .popup {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    }  */

    .popup::-webkit-scrollbar {
        width: .5em;
    }
        
    .popup::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
        
    .popup::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
  </style>